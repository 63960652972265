import React from 'react';
import Ticker from 'framer-motion-ticker'; 
import Dach1 from '../../assets/Dach1.png'
import Dach2 from '../../assets/Dach2.png'
import Dach3 from '../../assets/Dach3.png'
import Dach4 from '../../assets/Dach4.png'
import Dach5 from '../../assets/Dach5.png'
import Dach6 from '../../assets/Dach6.png'
import Dach7 from '../../assets/Dach7.png'
import Dach8 from '../../assets/Dach8.png'
import Dach9 from '../../assets/Dach9.webp'
import Dach10 from '../../assets/Dach10.webp'
import Dach11 from '../../assets/Dach11.jpg'
import Dach12 from '../../assets/Dach12.jpg'
import Dach13 from '../../assets/Dach13.jpg'

const images = [
  { src: Dach1, alt: 'Image 1' },
  { src: Dach2, alt: 'Image 2' },
  { src: Dach3, alt: 'Image 3' },
  { src: Dach4, alt: 'Image 4' },
  { src: Dach5, alt: 'Image 5' },
  { src: Dach6, alt: 'Image 6' },
  { src: Dach7, alt: 'Image 7' },
];

const images2 = [
  { src: Dach8, alt: 'Image 8' },
  { src: Dach9, alt: 'Image 9' },
  { src: Dach10, alt: 'Image 10' },
  { src: Dach11, alt: 'Image 11' },
  { src: Dach12, alt: 'Image 12' },
  { src: Dach13, alt: 'Image 13' },
];



const Ticker1 = () => {
  const [isPlaying, setIsPlaying] = React.useState(true)
  return (
    <div>
      <Ticker  onMouseEnter={() => setIsPlaying(false)} onMouseLeave={() => setIsPlaying(true)} isPlaying={isPlaying} duration={16}>
        {images.map((image) => (
           <img key={image.alt} src={image.src} alt={image.alt} className='ticker-image' />
        ))}
      </Ticker>
    </div>
  );
}; 

const Ticker2 = () => {
  const [isPlaying, setIsPlaying] = React.useState(true)
  return (
        <div className='ticker2'>
          <Ticker  onMouseEnter={() => setIsPlaying(false)} onMouseLeave={() => setIsPlaying(true)} isPlaying={isPlaying} duration={15} >
            {images2.map((image) => (
               <img key={image.alt} src={image.src} alt={image.alt} className='ticker-image ticker2' />
            ))}
          </Ticker>
        </div>    
  );
};

export { Ticker1, Ticker2}




