import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, } from "react-router-dom";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<BrowserRouter><App /></BrowserRouter>, rootElement);
} else {
  ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, rootElement);
}