import React from 'react';
import Logo from '../assets/Logo.png'
import {TypeAnimation} from 'react-type-animation' 
import {motion} from 'framer-motion' 
import {fadeIn} from '../variants' 
import Dach9 from '../assets/Dach9.webp'
import Dach10 from '../assets/Dach10.webp'

import oldDach from '../assets/oldDach.webp'
import newDach from '../assets/newDach.webp'
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage
} from "react-compare-slider";
import { useInView } from 'react-intersection-observer';
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import {Ticker1, Ticker2} from './components/Ticker';
import { Helmet } from 'react-helmet';

const Home = ( style, ...props ) => {
  const [ref, InView] = useInView({
    threshold: 0.5,
  });
 
  return(
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Home" />
            </Helmet>
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center pf bg1 margin-none' id='home'>
    <div className="container mx-auto ">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12 '>
        {/* text */}
        <div className='flex-1 text-center font-secondary lg:text-left'>
          <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='text-[55px] font-bold leading-[0.8] lg:text-[60px] bh1'>
            GUT BEDACHT AUS PLÖN  <span className='bh2 lg:text-[40px]'>BEHREND-DACH IN PLÖN SAGT</span>
            </motion.h1>
            <motion.div variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='mb-6 text-[36px] lg:text-[60px] pf uppercase leading-[1]'>
              <span className='mr-4'>...</span>
              <TypeAnimation sequence={[
                'Moin, Moin',
                2000,
                'Hallo',
                2000,
                'Hi',
                2000
              ]} 
              speed={50} 
              className='pf font-bold'
              wrapper='span'
              repeat={Infinity}
              />
            </motion.div>
            <motion.p variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='mb-8 max-w-lg mx-auto lg:mx-0 font-bold'>        
Hallo und herzlich willkommen auf unserer Website. Überzeugen Sie sich von unserer Qualität und Leistung auf dieser interaktiven Info-Website!
            </motion.p> 
            {/* Kontakt */}

            <Link to="/Kontakt">
            <motion.button variants={fadeIn('up', 0.9)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='btn btn-sm rounded-2xl font-bold text-[26px] borders'>In Kontakt treten</motion.button>
            </Link>
        </div>
          {/* image */}
        <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} className=' lg:flex flex-1 max-w-[320px] mb-5 movingI lg:max-w-[482px]'>
           <img src={Logo} alt='' />
          </motion.div>
      </div>
    </div>
  </section>
    <div className='h-[50px]'></div>
    <div className="container mx-auto mb-40">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center items-center lg:gap-x-12'>
        {/* image */}
      <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} className='showcase lg:flex flex-1 refpic'>
           <img alt='' className='rounded-md' src={Dach10}/>
          </motion.div>
        {/* text */}
        <motion.div variants={fadeIn('up', 0.2)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='flex-1 text-center glasscard lg:text-left text-black'>
        <motion.h2 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='text-[30px] font-bold bh2 leading-[1.2]'>
        Allgemein
            </motion.h2>
        <motion.h1 variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className='text-[45px] mb-5 font-bold bh1 leading-[1.2]'>
        Unsere Arbeit
            </motion.h1>
            <motion.p variants={fadeIn('up', 0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.4}} className=' mx-auto font-bold leading-[1.4] lg:mx-0 text-[20px]'>
            Wir beschäftigen uns mit dem gesamten Spektrum des Dachdeckerhandwerks, führen Kleinstreparaturen aus wie das Austauschen einer einzelnen Pfanne, bauen Dachfenster und Photovoltaikanlagen ein, führen aber natürlich auch energetische Sanierungen im Steil- und Flachdach aus. <br/><br/>
            Auch Blecharbeiten wie Dachentwässerung, Einfassungen und Stehfalz gehören zu unserem Aufgabenspektrum.
            </motion.p>
        </motion.div>
      </div>
    </div>
    <div className="container mx-auto mb-20">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center items-center lg:gap-x-12'>
        {/* text */}
        <motion.div variants={fadeIn('up', 0.2)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='flex-1 text-center glasscard lg:text-left text-black'>
        <motion.h2 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[30px] font-bold bh2 leading-[1.2]'>
        Nicht Ihr gewöhnlicher Dachdecker
            </motion.h2>
        <motion.h1 variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[45px] mb-5 font-bold bh1 leading-[1.2]'>
        Unsere Qualität
            </motion.h1>
            <motion.p variants={fadeIn('up', 0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className=' mx-auto font-bold leading-[1.4] lg:mx-0 text-[20px]'>
            Wir achten auf hohe Qualitätsstandards und verarbeiten hochwertige Produkte unserer<br/>
 <a className='bh1' href='https://www.behrend-dach.de/Partnerfirmen'>-Partnerfirmen.</a> <br/><br/>
Unsere Mitarbeiter nehmen regelmäßig an Schulungen teil.
            </motion.p>
        </motion.div>
        {/* image */}
      <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} className='showcase lg:flex flex-1 refpic'>
           <img className='rounded-md' alt='' src={Dach9}/>
          </motion.div>
      </div>
    </div>
         
         <div className='h-[300px] spc'/>
         <Ticker1/>
      <div className='h-[50px]'></div>
    <Ticker2/>
      
    
  
  <div className='h-[200px]'></div>
  <motion.div variants={fadeIn('up', 0.5)} initial="hidden" whileInView={'show'} >
  <div className='container mx-auto'>
  <h1 className='bh2 text-[28px] h-[60px] text-center'>Überzeugen Sie sich selbst!</h1>
  <h2 className='bh1 text-[40px] font-bold h-[130px] text-center  leading-10'>Mit einem Vorher-Nachher-Effekt einer Sanierung</h2>
  </div>
  
  <ReactCompareSlider className='container mx-auto shadow1' 
      {...props}
      handle={
        <ReactCompareSliderHandle
          buttonStyle={{
            backdropFilter: undefined,
            background: "white",
            border: 0,
            color: "#333"
          }}
        />
      }
      itemOne={
        <ReactCompareSliderImage
          src={oldDach}
          alt="one" 
        />
       
      }
      itemTwo={
        
        <ReactCompareSliderImage
          src={newDach}
          alt="two"
        /> 
      }
      style={{
        display: "flex",
        width: "100%",
        height: "50vh",
        ...style
      }}
    />
    
        <div className='h-[100px]'></div>
        <motion.div variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}>
        <h2 className='bh1 text-center text-[40px] font-bold h-[120px] leading-10'>Fördermöglichkeiten für Dachfenster der Firma Roto</h2>
        <a  href="https://www.roto-foerderservice.de/Startseite?action=c6925dfd-9255-415e-a028-fa2d488714cd" target="_blank"><img className='resize2' alt='' src="https://www.roto-foerderservice.de/images/banner2.jpg" /></a>
     </motion.div>
    
    
   
    </motion.div><div className='h-[100px]'></div>
  </>
  );
}; 

export default Home;


