import React from 'react';

import Logo from './assets/Logo.png';

import { Link, useMatch, useResolvedPath } from "react-router-dom"

import Insta from './assets/Insta.webp'

const Navbar = () => {
  return (
  <header className='py-5 snav '>
    <div className=' '> 
      <div className='flex justify-between items-center'>
          <Link to="/Home"  >
          <img src={Logo} alt='logo'  className='limg mld' />
         </Link>
        <div>   
        </div>    
         <div className='con1'>
        <Link to="/Home" >
        <button className=' rounded-2xl btn font-bold btn-sm mr-10  scale-110' >Home</button>
        </Link>
        <Link to="/Leistungen">
        <button className='btn btn-sm font-bold rounded-2xl mr-10 scale-110'>Leistungen</button>
        </Link>
        <Link to="/Karriere">
        <button className='btn btn-sm rounded-2xl font-bold mr-10 scale-110'>Karriere</button>
        </Link>
        <Link to="/Über_uns">
        <button className='btn btn-sm rounded-2xl font-bold mr-10  scale-110'>Über uns</button>
        </Link>
        <Link to="/Partnerfirmen">
        <button className='btn btn-sm rounded-2xl font-bold mr-10 scale-110'>Partnerfirmen</button>
        </Link>
        <Link to="/Referenzen">
        <button className='btn btn-sm rounded-2xl font-bold mr-10 scale-110'>Referenzen</button>
        </Link>
        <Link to="/Kontakt">
        <button className='btn btn-sm rounded-2xl font-bold mrd scale-110'>Kontakt</button>
      </Link>
      </div>
      </div> 
    </div>
  </header>
  );
};

export default Navbar;
