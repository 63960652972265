let timelineElements = [
    {
      id: 1,
      title: "Realschulabschluss",
      location: "In Plön",
      description:
        "",
      buttonText: "",
      date: "1991",
      icon: "school",
    },
    {
      id: 2,
      title: "Ausbildung",
      location: "In Plön",
      description:
        "Ausbildung zum Dachdecker bei der Firma Gebr. Behrend in Plön, mit Abschluss 1994 und Übernahme als Dachdecker.",
      date: "1991 - 1994",
      icon: "school",
    },
    {
      id: 3,
      title: "Ableistung des Grundwehrdiensts",
      location: "",
      description:
        "",
      buttonText: "",
      date: "1995 - 1996",
      icon: "school",
    },
    {
      id: 4,
      title: "Dachdecker bei Firma Gebr. Behrend ",
      location: "In Plön",
      description:
        "",
      buttonText: "",
      date: "2003 - 2013",
      icon: "work",
    },
    {
      id: 5,
      title: "Besuch der Dachdeckermeisterschule mit Abschluss am 12.06.2014 vor der Handwerkskammer Lübeck",
      location: "",
      description:
        "",
      buttonText: "",
      date: "2013 - 2014",
      icon: "work",
    },
    {
      id: 6,
      title: "Dachdeckermeister bei Firma Gebr. Behrend in Plön",
      location: "",
      description:
        "",
      buttonText: "",
      date: "2014 - 2018",
      icon: "work",
    },
    {
      id: 7,
      title: "",
      location: "",
      description: <div>
        Start der neuen Firma <a className="bh1 text-[20px]"> Behrend-Dach</a>, die aus der Firma  Gebr. Behrend hervorgegangen ist</div>,
      date: "01.07.2018",
      icon: "work",
    },
    {
      id: 8,
      title: <a className="bh1" href="https://www.behrend-dach.de/tl_files/2019/urkunde_betriebswirt_bartnik_2019.pdf">Geprüfter Betriebswirt (HwO)</a>,
      location: "",
      description:
        "",
      buttonText: "",
      date: "2019",
      icon: "work",
    },

  ];
  
  export default timelineElements;