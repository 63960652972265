import React from 'react';
import {motion, InView} from 'framer-motion' 
import {fadeIn} from '../variants'
import Timeline from './Timeline'; 
import Bartnik from '../assets/Bartnik.jpg'
import Filler from '../assets/up.png'
import { Helmet } from 'react-helmet';



export default function Überuns() {
  return <>
  <Helmet>
                <meta charSet="utf-8" />
                <title>Über_uns</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Über_uns" />
            </Helmet>
  {/*<section className='min-h-[85vh] lg:min-h-[78vh] flex items-center pf bg2 margin-none' id='home'>
    <div className="container mx-auto ">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12 '>
       
        <div className='flex-1 text-center  lg:text-left  shadow borders1  rounded-2xl  gap-y-6  p-6 '>
          
          <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[50px] font-bold leading-[0.8] lg:text-[60px] bh1'>
            Das Team von<br/> Behrenddach
            </motion.h1>
            <motion.div variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-6 text-[36px]  pf leading-[1]'>
              <div className='h-[20px]'></div>
              <div >
                <a className='bh2 font-bold'>
                  Hier erzählen wir euch mehr <br/> über die Geschichte und die <br/> talentierten Mitarbeiter unserer Firma
                </a>
              </div>
            </motion.div>
        </div>
          <div className='w-[450px]'></div>
      </div>
    </div>
  </section>*/}
  <div className='h-[200px]'></div>
  <div className='container mx-auto'>
    <div className='flex flex-col lg:flex-row'>
    <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='  flex flex-1 flex-col gap-y-6  p-6 items-start amarg'>
        
        <h1 className='bh1 text-[50px] leading-10 font-bold'>Daniel Bartnik</h1>
        <a className='pfs1 font-bold text-[26px]'>Der Inhaber der Firma</a>
        <div className='h-[5px]'></div>
        <a className='pfs1 font-bold text-[26px]'>Dachdeckermeister</a>
        
        <a className='pfs1 font-bold text-[26px]'>Betriebswirt</a>
      </motion.div>
      <div className=' rounded-xl'>
      <motion.img variants={fadeIn('right', 0.4)} initial="hidden" whileInView={'show'}  whileHover={{ scale: 1.05 }}whileTap={{ scale: 0.9 }}  src={Bartnik} alt='' className=' rounded-xl idk borders1'/>
    </div>
    </div>
  </div>
  <div className='h-[200px]'></div>
  <Timeline/>
  
  {/*<div className='h-[200px]'></div>
  <div className='text-center'>
  <a className='bh2 font-bold text-[50px]'>Jetzt mehr über einige unserer Mitarbeiter</a>
  </div>
  <div className='h-[300px]'></div>
  <div className=' mx-auto amarg-'>
    <div className='flex flex-col lg:flex-row gap-x-10 '>
       <div className='w-[150px]'></div>
               <div className='group relative overflow-hidden border-2 border-black/50  rounded-2xl sizey Arbeiter'>
                  
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                 
                  <img className='group-hover:scale-125 transition-all duration-500 sizey' src={Filler} alt='' />
                 
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Arbeiter </span> 
                  </div>
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white '>Info </span>
                  </div>
                  <div className='absolute -bottom-full left-80 group-hover:bottom-5 transition-all duration-700 z-50'>
                   
                  </div>
               </div>     
               
              
           
              
              <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl sizey Arbeiter'>
                   
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  
                  <img className='group-hover:scale-125 transition-all duration-500 sizey' src={Filler} alt='' />
                 
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Arbeiter</span> 
                  </div>
                 
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '>Info</span>
                  </div>
               </div> 
               <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl sizey Arbeiter'>
                   
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  
                  <img className='group-hover:scale-125 transition-all duration-500 sizey' src={Filler} alt='' />
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Arbeiter</span> 
                  </div>
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '>Info</span>
                  </div>
               </div>     
            
    </div>
  </div>
  <div className='h-[200px]'></div>
  <div className=' mx-auto'>
    <div className='flex flex-col lg:flex-row gap-x-10 amarg-'>
       <div className='w-[150px]'></div>
               <div className='group relative overflow-hidden border-2 border-black/50  rounded-2xl  sizey Arbeiter'>
                   
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  
                  <img className='group-hover:scale-125 transition-all duration-500 sizey' src={Filler} alt='' />
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Arbeiter </span> 
                  </div>
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white '>Info </span>
                  </div>
                  <div className='absolute -bottom-full left-80 group-hover:bottom-5 transition-all duration-700 z-50'>
                  
                  </div>
               </div>     
               
              
           
              
              <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl sizey Arbeiter'>
                   
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                 
                  <img className='group-hover:scale-125 transition-all duration-500 sizey' src={Filler} alt='' />
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Arbeiter</span> 
                  </div>
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                  <span className='text-3x1 text-white '>Info </span>
                  </div>
               </div> 
               <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl sizey Arbeiter'>
                   
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  
                  <img className='group-hover:scale-125 transition-all duration-500 sizey' src={Filler} alt='' />
                 
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Arbeiter</span> 
                  </div>
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                  <span className='text-3x1 text-white '>Info </span>
                  </div>
               </div>     
            
    </div>
  </div>*/}
  <div className='h-[400px]'></div>

  
</>
}