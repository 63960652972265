import React from 'react';
import JP4 from '../assets/Dachdecker-gesucht.webp'
import { Helmet } from 'react-helmet';





const Karriere = () => {
  return (
   <>
   <Helmet>
                <meta charSet="utf-8" />
                <title>Karriere</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Karriere" />
            </Helmet>
   <div className="container  mx-auto">
        <div className='flex-1 text-center mb-10 font-secondary '>
          <div className='lg:text-[60px] text-[50px] font-bold bh1 leading-[0.8] '>
          Berufliche<br/> Möglichkeiten
            </div>  
        </div>
          {/* image */}

   <img className='mb-10 mx-auto lg:w-1/3 md:w-2/4  border-2 border-black/50 rounded-xl' src={JP4} alt='Dachdecker-gesucht' />
    </div >
   </>
  );
};

export default Karriere;