import * as React from "react";
import { motion } from "framer-motion";
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import Insta from './assets/Insta.webp'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    display: 'block',
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    display: 'none',
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const colors = []

export const MenuItem = ({ i, onClick }) => {
  const style = { border: `2px solid ${colors[i]}` };
  return (
     
    <div className="font-bold">
      
      <motion.lis
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      </motion.lis>
    <motion.lis
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
     
      <div className="h-[10px]" ></div>
      <CustomLink onClick={onClick} to="/">
      <a href="https://www.behrend-dach.de/Home" className="bh2 text-[30px]" >Home </a>  
      </CustomLink> 
      
    </motion.lis> 
    {/* add this back when complete <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <CustomLink to="/Dächer">
    <a className="bh2 text-[30px]" >Dächer </a>  
    </CustomLink>  
    
  </motion.lis>
  <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <CustomLink to="/Solarsysteme">
    <a className="bh2 text-[30px]" >Solarsysteme </a>  
    </CustomLink> 
    
  </motion.lis>*/}
  <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <CustomLink onClick={onClick} to="/Über_uns">
    <a href="https://www.behrend-dach.de/Über_uns" className="bh2 text-[30px]" >Über uns </a>  
    </CustomLink> 
    
  </motion.lis>
  <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <CustomLink onClick={onClick} to="/Karriere">
    <a href="https://www.behrend-dach.de/Karriere" className="bh2 text-[30px]" >Karriere</a>  
    </CustomLink> 
    
  </motion.lis>
  <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <CustomLink onClick={onClick} to="/Partnerfirmen">
    <a href="https://www.behrend-dach.de/Partnerfirmen" className="bh2 text-[30px]" >Partnerfirmen </a>  
    </CustomLink> 
    
  </motion.lis><motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <CustomLink onClick={onClick} to="/Referenzen">
    <a href="https://www.behrend-dach.de/Referenzen" className="bh2 text-[30px]" >Referenzen </a>  
    </CustomLink> 
    
  </motion.lis>
  <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <CustomLink onClick={onClick} to="/Leistungen">
    <a href="https://www.behrend-dach.de/Leistungen" className="bh2 text-[30px]" >Leistungen </a>  
    </CustomLink> 
    
</motion.lis>
  <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <CustomLink onClick={onClick} to="/Kontakt">
    <a href="https://www.behrend-dach.de/Kontakt" className="bh2 text-[30px]" >Kontakt</a>  
    </CustomLink> 
    
  </motion.lis>
  <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
  <div>   
      <a href='https://instagram.com/behrenddach?igshid=YmMyMTA2M2Y='>
          <img src={Insta} alt='insta' className='insta'/>
          </a>
        </div> 
        </motion.lis>
  </div>


  );
};

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}