import { useEffect } from 'react';

import rpng from '../assets/eRecht.png'

const Impressum = () =>{
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);
    return(
        
        <div className="container mx-auto">
          <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
            {/* text */}
            <div className='flex-1 text-center font-secondary lg:text-left'>
               <h1 id='top' className='bh2 font-bold text-[25px]'>IMPRESSUM</h1><br/>
             <div className='mb-8 max-w-lg mx-auto lg:mx-0 pfs1'>
                <p className='bh2 text-[25px]'>ANGABEN GEMÄSS § 5 TMG</p>
                Behrend-Dach GmbH 
                Lübecker Straße 4<br/>
                24306 Plön<br/>
                
                Handelsregister: HRB 19575<br/>
                Registergericht: Kiel<br/>
                
                <p className='pfs'>Vertreten durch:</p>
                Dachdeckermeister Daniel Bartnik<br/><br/>
                <p className='bh2 text-[25px]'>Kontakt</p> 
                Telefon: 04522 802 960 <br/>
                E-Mail: info@behrend-dach.de <br/><br/>

                <p className='bh2 text-[25px]'>UMSATZSTEUER-ID</p>
                Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: 
                DE 815767257 <br/><br/>
                
                <p className='bh2 text-[25px]'>EU-STREITSCHLICHTUNG</p>
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href='https://ec.europa.eu/consumers/odr/.' className='bh1'>https://ec.europa.eu/consumers/odr/.</a> <br/>
                Unsere E-Mail-Adresse finden Sie oben im Impressum.<br/><br/>
                <p className='bh2 text-[25px]'>VERBRAUCHER­STREIT­BEILEGUNG/UNIVERSAL­SCHLICHTUNGS­STELLE</p>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br/><br/>
                <p className='bh2 text-[25px]'>Konzeption, Gestaltung und technische Umsetzung</p>
                Jeremiah Hetzel
                <br/><br/>
                <p className='bh2 text-[25px]'>Fotos</p>
                Daniel Bartnik<br/><br/>
             </div> 
          </div>
        </div>
        </div>
    );
}; 

export default Impressum;