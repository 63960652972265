import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';

import {motion} from 'framer-motion';

import {fadeIn} from '../variants';
import { Helmet } from 'react-helmet';





const Kontakt = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_t5vb4xa', 'template_ps6el48', form.current, 'fw33Bw1Q130Jk7JCJ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      alert ("Email Erfolgreich versendet")
  };

 

  return (
    <div className='contact'>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Kontakt</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Kontakt" />
            </Helmet>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text */}
          <motion.div 
          variants={fadeIn('right', 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className='flex-1 flex justify-start items-center'>
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide '>Get in Touch</h4> 
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12 pfs'>Let's work <br/> together! </h2>
              <h3 className='pfs text-[25px]'>Individuelle Lösungen für jedes Dach. Vereinbaren Sie noch heute einen Termin!</h3>
            </div>
          </motion.div>
          {/* form */}
          <motion.form 
          variants={fadeIn('left', 0.4)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className='flex-1 borders1 rounded-2xl flex flex-col gap-y-6  p-6 items-start' ref={form} onSubmit={sendEmail}>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all pfs' name='Subject' type="text" placeholder='Betreff'/>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all pfs' name='name' type="text" placeholder='Straße, Hausnummer'/>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all pfs' name='Firma' type="text" placeholder='Postleitzahl, Ort'/>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all pfs' name='Telefon' type="text" placeholder='Telefon*'/>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all pfs' type="email" name='email' placeholder='Email*'/>
          
          <textarea className='bg-transparent border-b py-12 pfs outline-none w-full placeholder:text-Gray focus:border-accent transition-all resize-none mb-12' name='message' placeholder=' Ihre Nachricht an uns*'></textarea>
          <div className='bh2'>Bitte füllen Sie die mit * gekennzeichneten Felder aus.</div>
          <div className='bh2'>Wenn Sie per E-Mail oder über dieses Formular Kontakt mit uns aufnehmen, gilt die <Link className='bh1' to='/Datenschutz'>Datenschutzerklärung.</Link></div>
          <button className='btn btn-sm text-[20px] h-[60px]' type="submit" value="Send">Absenden</button> 
          </motion.form>
        </div>
        
      </div>
   </div>
  );
};

export default Kontakt;